import { Modal } from 'antd';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { For } from 'react-utilify';
import { AnimatePresence, motion } from 'framer-motion';
import { useOnClickOutside } from 'components/VideoLessons/useClickOutside';

function VideoLessons() {
	const { t, i18n } = useTranslation();
	const btnRef = useRef(null);

	useOnClickOutside(btnRef, () => {
		setIsOpen(false);
	});

	const [isOpen, setIsOpen] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [video, setVideo] = useState<{
		id: number;
		title: React.ReactNode;
		url?: string;
		embed?: {
			ru: string;
			uz_latn: string;
			uz_cyrl: string;
		};
	} | null>(null);

	const videos = [
		{
			id: 2,
			title: t('Qadoq yaratish'),
			embed: {
				ru: `<iframe width="100%" height="500" src="https://www.youtube.com/embed/_ipJU0VvWrA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
				uz_latn: `<iframe width="100%" height="500" src="https://www.youtube.com/embed/qG_E-rEVngU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
				uz_cyrl: `<iframe width="100%" height="500" src="https://www.youtube.com/embed/qG_E-rEVngU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
			},
		},
		{
			id: 3,
			title: t("Yangi MXIK yaratish uchun so'rovnoma yuborish"),
			embed: {
				ru: `<iframe width="100%" height="500" src="https://www.youtube.com/embed/f3ZLzmu1b9g?si=OG05zrpaMlhuatQ8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
				uz_latn: `<iframe width="100%" height="500" src="https://www.youtube.com/embed/9iQs3DfAZnE?si=GokwLenNvoefk9i3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
				uz_cyrl: `<iframe width="100%" height="500" src="https://www.youtube.com/embed/9iQs3DfAZnE?si=GokwLenNvoefk9i3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
			},
		},
		{
			id: 4,
			title: t(`Tanlaganlarga qo'shish`),
			embed: {
				ru: `<iframe width="100%" height="500" src="https://www.youtube.com/embed/qvyzuM7c5A4?si=zQBb8sxsoR0jPnYE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
				uz_latn: `<iframe width="100%" height="500" src="https://www.youtube.com/embed/La9NlLVOvz4?si=hNE71gqVXXU2omDp" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
				uz_cyrl: `<iframe width="100%" height="500" src="https://www.youtube.com/embed/La9NlLVOvz4?si=hNE71gqVXXU2omDp" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
			},
		},
		{
			id: 5,
			title: t(`Qurilsh materiali uchun MXIK qanday topiladi?`),
			embed: {
				ru: `<iframe width="100%" height="500" src="https://www.youtube.com/embed/qSWwe6gQI4U?si=YxrZmJRE4Zet5C8c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
				uz_latn: `<iframe width="100%" height="500" src="https://www.youtube.com/embed/WUAlgEMSYW4?si=yeI8U33FIVJJEIQw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
				uz_cyrl: `<iframe width="100%" height="500" src="https://www.youtube.com/embed/WUAlgEMSYW4?si=yeI8U33FIVJJEIQw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
			},
		},
	];

	const vidRef = useRef<HTMLVideoElement>(null);

	const stopVideo = () => {
		vidRef.current && vidRef.current.pause();
	};

	return (
		<div
			ref={btnRef}
			className='fixed !bottom-5 !right-5 md:!bottom-12 md:!right-16 w-48 md:w-56 space-y-2 flex flex-col items-end z-50'
		>
			<Modal
				title={<div className='text-xl'>{video?.title}</div>}
				visible={isModalOpen}
				footer={false}
				onCancel={() => {
					stopVideo();
					setIsModalOpen(false);
				}}
				width={1000}
				bodyStyle={{ padding: '0px 20px 10px' }}
			>
				{video &&
					isModalOpen &&
					(video.embed ? (
						<div
							dangerouslySetInnerHTML={{ __html: isModalOpen ? video.embed[i18n.language as keyof typeof video.embed] : '' }}
						/>
					) : (
						<video ref={vidRef} className='w-full !h-[450px]' controls>
							<source src={video.url} type='video/mp4' />
							Your browser does not support the video tag.
						</video>
					))}
			</Modal>

			{/* @ts-ignore */}
			<AnimatePresence>
				<div className='z-50 flex flex-col items-end w-56 py-3 space-y-5 overflow-x-visible overflow-y-hidden'>
					{isOpen ? (
						<For each={videos}>
							{(video, idx) => (
								<motion.div
									key={video.id}
									transition={{ duration: 0.5, type: 'spring' }}
									initial={{ y: 100 * (idx + 1), opacity: 0 }}
									animate={{ y: 0, opacity: 100 }}
									exit={{ y: -50 * (idx + 1), opacity: 0 }}
									onClick={() => {
										setIsOpen(false);
										setVideo(video);
										setIsModalOpen(true);
									}}
									className='flex items-center justify-start w-full gap-2 px-5 py-1 border border-gray-200 rounded-full shadow-sm cursor-pointer bg-gray-50 active:scale-95'
								>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='20'
										height='20'
										fill='none'
										stroke='currentColor'
										strokeLinecap='round'
										strokeLinejoin='round'
										strokeWidth='1'
										className='flex-shrink-0 text-gray-900'
										viewBox='0 0 24 24'
									>
										<circle cx='12' cy='12' r='10'></circle>
										<path d='M10 8L16 12 10 16 10 8z'></path>
									</svg>

									<p className='mb-0 text-gray-900'>{video.title}</p>
								</motion.div>
							)}
						</For>
					) : null}
				</div>
			</AnimatePresence>

			<div
				onClick={() => setIsOpen(!isOpen)}
				className='flex items-center justify-center flex-grow-0 w-full h-10 gap-2 px-5 duration-150 rounded-full shadow-lg cursor-pointer bg-blue-default active:scale-95'
			>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					fill='none'
					stroke='currentColor'
					strokeLinecap='round'
					strokeLinejoin='round'
					strokeWidth='2'
					className='text-white !h-5 !w-5 md:!h-6 md:!w-6'
					viewBox='0 0 24 24'
				>
					<circle cx='12' cy='12' r='10'></circle>
					<path d='M10 8L16 12 10 16 10 8z'></path>
				</svg>

				<p className='mb-0 text-sm font-medium text-center text-white md:text-base md:font-bold'>
					{t("Video qo'llanmalar")}
				</p>
			</div>
		</div>
	);
}

export default VideoLessons;
